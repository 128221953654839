import React from "react";
import Home from "./Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from "@mui/material";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/Home#que-hacemos",
      element: <Home />,
    },
    {
      path: "/Home#porque-elegirnos",
      element: <Home />,
    },
    {
      path: "/Home#contacto",
      element: <Home />,
    },
  ]);
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Calderas Norte</title>
        </Helmet>
        <RouterProvider router={router}></RouterProvider>
      </div>{" "}
    </ThemeProvider>
  );
};

export default App;
