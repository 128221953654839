import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Toolbar,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Slide,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "white",
  justifyContent: "space-between",
}));

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  display: "flex",
  color: "#01524c",
  fontWeight: "bold",
  fontSize: "12px",
  "&:hover": {
    transition: "all",
  },
}));

const BurgerMenu = () => {
  const [toggle, setToggle] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const urlRoutes = [
    { path: "/#que-hacemos", name: "¿QUE HACEMOS?" },
    { path: "/#servicios", name: "NUESTROS SERVICIOS" },
    { path: "/#porque-elegirnos", name: "¿POR QUÉ ELEGIRNOS?" },
    { path: "/#contacto", name: "CONTACTO" },
  ];

  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "0px";
      document.body.classList.add("no-scroll");
    } else {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
      document.body.classList.remove("no-scroll");
    }
  }, [toggle]);

  return (
    <>
      <StyledToolbar>
        {isMobile ? (
          <IconButton
            onClick={() => setToggle(!toggle)}
            sx={{ display: { xs: "block", md: "none" }, color: "#01524c" }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <StyledList>
            {urlRoutes.map((item) => (
              <ListItem key={item.name} sx={{ margin: "0 20px" }}>
                <ListItemText>
                  <StyledButton
                    className="navbar-links"
                    component={Link}
                    to={item.path}
                  >
                    {item.name}
                  </StyledButton>
                </ListItemText>
              </ListItem>
            ))}
          </StyledList>
        )}
      </StyledToolbar>

      <Drawer anchor="top" open={toggle} onClose={() => setToggle(false)}>
        <Slide direction="down" in={toggle} mountOnEnter unmountOnExit>
          <List sx={{ background: "white", color: "#01524c" }}>
            {urlRoutes.map((item) => (
              <ListItem button key={item.name} onClick={() => setToggle(false)}>
                <ListItemText>
                  <StyledButton
                    className="navbar-links"
                    component={Link}
                    to={item.path}
                  >
                    {item.name}
                  </StyledButton>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Slide>
      </Drawer>
    </>
  );
};

const style = document.createElement("style");
style.innerHTML = `
  .no-scroll {
    overflow: hidden;
    /* Optional: to hide scrollbar while keeping the content accessible */
    position: fixed;
    width: 100%;
  }
`;
document.head.appendChild(style);

export default BurgerMenu;
