import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  CircularProgress,
  Typography,
  styled,
} from "@mui/material";

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Montserrat",
  display: "flex",
  textTransform: "initial",
  color: "white",
  width: "40%",
  backgroundColor: "#115e59",
  fontSize: "15px",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  "&:hover": {
    color: "white",
    transition: "all",
  },
}));
const prodUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMailComplete, setIsMailComplete] = useState(false);
  const [isMailRejected, setIsMailRejected] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsMailComplete(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsMailComplete(false);
      setIsLoading(true);
      const response = await fetch(prodUrl + "/send-email.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result) {
        setIsLoading(false);

        if (result.status === 200) {
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
          setIsMailComplete(true);
        } else {
          setIsMailRejected(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsMailRejected(true);
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#2D2C2C",
              },
              "&:hover fieldset": {
                borderColor: "#2D2C2C",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2D2C2C",
              },
            },
          }}
          InputProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              color: "#2D2C2C",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#2D2C2C",
              fontWeight: "400",
            },
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#2D2C2C",
              },
              "&:hover fieldset": {
                borderColor: "#2D2C2C",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2D2C2C",
              },
            },
          }}
          InputProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              color: "#2D2C2C",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#2D2C2C",
              fontWeight: "400",
            },
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#2D2C2C",
              },
              "&:hover fieldset": {
                borderColor: "#2D2C2C",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2D2C2C",
              },
            },
          }}
          InputProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              color: "#2D2C2C",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#2D2C2C",
              fontWeight: "400",
            },
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Mensaje"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          required
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#2D2C2C",
              },
              "&:hover fieldset": {
                borderColor: "#2D2C2C",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#2D2C2C",
              },
            },
          }}
          InputProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              color: "#2D2C2C",
            },
          }}
          InputLabelProps={{
            style: {
              color: "#2D2C2C",
              fontWeight: "400",
            },
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          {isLoading === true ? <CircularProgress /> : null}
          {isMailComplete === true ? (
            <Typography
              style={{
                color: "#65a30d",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                padding: "5px",
                marginBottom: "10px",
                backgroundColor: "#ecfccb",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              Mensaje enviado correctamente
            </Typography>
          ) : null}
          {isMailRejected === true ? (
            <Typography
              style={{
                color: "#b91c1c",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                padding: "5px",
                marginBottom: "10px",
                backgroundColor: "#fee2e2",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              No se pudo enviar tu mensaje, revisa los campos o intenta
              nuevamente.
            </Typography>
          ) : null}
        </div>

        <StyledSubmitButton type="submit">Enviar</StyledSubmitButton>
      </form>
    </Container>
  );
};

export default ContactForm;
