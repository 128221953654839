// WhatsAppButton.js
import React from "react";
import { Fab } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppButton = () => {
	const phoneNumber = "+5491155606446";
	const message = "Hola! necesitaria información sobre";
	const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
		message
	)}`;

	return (
		<a
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			style={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}
		>
			<Fab color="success" aria-label="WhatsApp">
				<WhatsAppIcon />
			</Fab>
		</a>
	);
};

export default WhatsAppButton;
