import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import "./Home.css";
import { Typography, Button, styled, Paper } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AnimatedCard from "./components/AnimatedCard";
import WhatsAppButton from "./components/WhatsAppButton";
import ContactForm from "./components/ContactForm";

const imageArray = [
  {
    img: "/services/1.png",
    title: "Codigos de error y deteccion de fallas",
    rows: 1,
    cols: 1,
  },
  {
    img: "/services/2.png",
    title: "Sistema simple por radiadores",
    rows: 1,
    cols: 1,
  },
  {
    img: "/services/3.png",
    title: "Baja de presión recurrente",
    rows: 1,
    cols: 1,
  },
  {
    img: "/services/4.png",
    title: "Problemas recurrentes",
    rows: 1,
    cols: 1,
  },
  {
    img: "/services/5.png",
    title: "Sistema bitermico y tiro forzado",
    rows: 1,
    cols: 1,
  },
  {
    img: "/services/6.png",
    title: "Sobre la presion correcta y duracion de la caldera",
    rows: 1,
    cols: 1,
  },
];

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Montserrat",
  display: "flex",
  textTransform: "initial",
  color: "white",
  backgroundColor: "#17B051",
  fontSize: "15px",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  "&:hover": {
    color: "white",
    transition: "all",
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontWeight: "400",
  color: "#181717",
  fontSize: "25px",
  lineHeight: "22px",
  width: "100%",
  marginBottom: "20px",
}));

const StyledCenterTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontWeight: "400",
  color: "#181717",
  fontSize: "25px",
  lineHeight: "22px",
  width: "100%",
  textAlign: "center",
  marginBottom: "20px",
}));
const StyledsubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontWeight: "400",
  color: "#181717",
  fontSize: "15px",
  lineHeight: "20px",
  textAlign: "justify",
  width: "100%",
}));
const StyledsubTitleCenter = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontWeight: "400",
  color: "#181717",
  fontSize: "15px",
  lineHeight: "20px",
  textAlign: "center",
  width: "100%",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "Montserrat",
  color: "#B31632",
  fontSize: "15px",
  fontWeight: "bold",
  lineHeight: "20px",
  width: "100%",
  marginBottom: "15px",
}));

const items = [
  {
    icon: <SupportAgentIcon sx={{ color: "#B31632", fontSize: "50px" }} />,
    text: "Atención Personalizada",
  },
  {
    icon: <BusinessCenterIcon sx={{ color: "#B31632", fontSize: "50px" }} />,
    text: "Garantía de Nuestros Trabajos",
  },
  {
    icon: <EmojiEventsIcon sx={{ color: "#B31632", fontSize: "50px" }} />,
    text: "+10 Años de Experiencia",
  },
  {
    icon: <ReceiptIcon sx={{ color: "#B31632", fontSize: "50px" }} />,
    text: "Presupuesto en el Acto",
  },
];

const Home = () => {
  const location = useLocation();

  const phoneNumber = "+5491155606446";
  const message = "Hola! necesitaria información sobre";
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  const handleClick = () => {
    window.open("https://www.facebook.com/calderasnorte1", "_blank");
  };

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        const headerOffset = 100;
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  return (
    <>
      <Navbar />
      <div className="banner-container">
        <div className="content-container">
          <div
            className="title-banner"
            item
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <StyledTitle>
              <strong>CALDERAS NORTE</strong>
            </StyledTitle>
            <br />
            <StyledsubTitle>
              El mal funcionamiento de una caldera nos suele encontrar de manera
              inesperada. Las averías, siempre sorpresivas, surgen en los peores
              momentos y terminan por afectarnos en nuestra rutina diaria.
            </StyledsubTitle>
            <br />
            <br />
            <StyledButton component={Link} to={url} sx={{ fontSize: "12px" }}>
              ACERCANOS TU CONSULTA <WhatsAppIcon sx={{ marginLeft: 2 }} />
            </StyledButton>
          </div>

          <div xs={6}>
            <div className="form-bg">
              <StyledTitle
                sx={{ color: "#01524C", fontSize: "20px", padding: "0px 24px" }}
              >
                <strong>CONTACTO DIRECTO</strong>
              </StyledTitle>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="services-text">
        <div className="product-img-container">
          <img
            src={"worker-repairing-water-heater.jpg"}
            alt="Your description"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="services-content" id="que-hacemos">
          <StyledLabel>¿QUÉ HACEMOS?</StyledLabel>
          <StyledTitle>
            <strong>URGENCIA Y SOLUCIONES</strong>
          </StyledTitle>
          <br />
          <StyledsubTitle className="services-subtitle">
            Debemos resolverlo urgente. Lo necesitamos hacer, porque hay cosas
            que luego de un día arduo de trabajo no nos pueden faltar: sin agua
            caliente, será imposible darnos nuestro merecido baño; sin
            calefacción, no podremos templar nuestro hogar a nuestro deseo y, en
            definitiva, sin caldera, no podremos relajarnos como corresponde.
            Debido al carácter urgente del problema, a veces nos cuesta mucho
            elegir un buen servicio técnico. Esta es una instancia clave y, para
            ello, debemos tener en consideración los siguientes factores:
          </StyledsubTitle>
          <br />
          <StyledsubTitle className="product-subtitle">
            Que nos asistan rápidamente, acercándonos opciones para lograr una
            solución efectiva.
          </StyledsubTitle>
          <br />
          <StyledsubTitle className="product-subtitle">
            Que nos asesoren bien para que podamos evitar, de manera simple,
            futuras averías comunes a todas las calderas a gas.
          </StyledsubTitle>
        </div>
      </div>

      <div className="services" id="servicios">
        <div className="services-img-container">
          {imageArray.map((item, index) => (
            <div key={index}>
              <AnimatedCard
                imageSrc={item.img}
                name={item.title}
                urlRoute={item.route}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="about-us-content">
        <div className="title-align">
          <StyledCenterTitle id="porque-elegirnos">
            <strong>¿POR QUÉ ELEGIRNOS?</strong>
          </StyledCenterTitle>
        </div>
        <br />
        <StyledsubTitleCenter className="product-subtitle">
          Creemos que Calderas Norte es una muy buena opcion. Porque cubrimos
          las primeras necesidades y mucho mas
        </StyledsubTitleCenter>
        <StyledsubTitleCenter className="product-subtitle">
          Nosotros nos ponemos en su lugar e intentamos brindarle soluciones.
        </StyledsubTitleCenter>
        <StyledsubTitleCenter className="product-subtitle">
          Hacemos para su hogar lo mismo que haríamos para el nuestro y esto
          marca una gran diferencia a la hora de prestar asistencia y servicio.
        </StyledsubTitleCenter>
        <StyledsubTitleCenter className="product-subtitle">
          Contamos con opciones de reparación de partes clave, como ser placas y
          válvulas electrónicas de gas.
        </StyledsubTitleCenter>
        <StyledsubTitleCenter className="product-subtitle">
          Intentamos estar más cerca, para que nos puedan consultar y despejar
          todas sus dudas.
        </StyledsubTitleCenter>
      </div>

      <div className="icon-container">
        <div className="icon-gap">
          {items.map((item, index) => (
            <div className="card-container">
              <Paper
                elevation={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  borderRadius: "8px",
                  width: "200px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div style={{ alignItems: "center" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {item.icon}
                  </div>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "Montserrat", fontSize: "12px" }}
                  >
                    {item.text}
                  </Typography>
                </div>
              </Paper>
            </div>
          ))}
        </div>
      </div>
      <div class="outer-div">
        <div class="inner-div"></div>
        <div class="background-div" id="contacto">
          <div className="content-container">
            <div
              className="title-banner"
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <StyledTitle sx={{ color: "#0d9488", fontSize: "20px" }}>
                <strong>CONTACTO DIRECTO</strong>
              </StyledTitle>
              <StyledsubTitle sx={{ color: "#0d9488" }}>
                <strong>Dirección</strong>
              </StyledsubTitle>
              <StyledsubTitle sx={{ color: "white" }}>
                25 de mayo 736, San Isidro, Buenos Aires
              </StyledsubTitle>
              <br />
              <StyledsubTitle sx={{ color: "#0d9488" }}>
                <strong>Teléfono</strong>
              </StyledsubTitle>
              <StyledsubTitle sx={{ color: "white" }}>
                (011) 15-5560-6446
              </StyledsubTitle>
              <br />
              <StyledsubTitle sx={{ color: "#0d9488" }}>
                <strong>Email</strong>
              </StyledsubTitle>
              <StyledsubTitle sx={{ color: "white" }}>
                dgabiss@gmail.com
              </StyledsubTitle>
              <br />
              <div className="facebook">
                <Button
                  sx={{
                    backgroundColor: "#0d9488",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    color: "white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  endIcon={
                    <FacebookOutlinedIcon
                      sx={{
                        color: "white",
                        fontSize: "50px",
                        borderRadius: "25px",
                      }}
                    />
                  }
                  onClick={handleClick}
                >
                  {" "}
                  Visitanos
                </Button>
              </div>
            </div>

            <div xs={6}>
              <div className="form-bg">
                <strong>CONTACTANOS</strong>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton />
    </>
  );
};
export default Home;
