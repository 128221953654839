import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./AnimatedCard.css";

const ImageContainer = styled(Box)(({ hover }) => ({
  position: "relative",
  width: "100%",
  height: "auto",
  overflow: "hidden",
  transform: hover ? "scale(1.05)" : "scale(1)",
  transition: "transform 0.3s ease",
}));

const Overlay = styled(Box)(({ hover }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingBottom: "5%",
  border: "4px solid white",
  transition: "background-color 0.3s ease",
}));

const AnimatedCard = ({ imageSrc, name, urlRoute }) => {
  const [hover, setHover] = React.useState(false);

  return (
    <ImageContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      hover={hover}
    >
      <Button style={{ padding: "0px" }}>
        <img
          src={imageSrc}
          alt="Your description"
          style={{ width: "100%", height: "100%" }}
        />
        <Overlay hover={hover}>
          <div
            style={{
              width: "90%",
              height: "100px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgba(179, 22, 50, 0.8)",
              margingBottom: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className="Image-product-title">
              <strong>{name}</strong>
            </Typography>
          </div>
        </Overlay>
      </Button>
    </ImageContainer>
  );
};

export default AnimatedCard;
