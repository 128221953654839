import React from "react";
import { Button, Box, styled } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import "./Navbar.css";

const StyledButton = styled(Button)(({ theme }) => ({
  listStyle: "none",
  fontFamily: "Inter",
  display: "flex",
  fontWeight: "bold",
  textTransform: "initial",
  color: "#01524c",
  fontSize: "12px",
  "&:hover": {
    transition: "all",
  },
}));

const Navbar = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <div className="navbar-title">
        <div className="button-nav-container">
          <StyledButton component={Link} to="/">
            <img
              src="/logotipo.jpg"
              alt="plato de ducha"
              loading="lazy"
              className="logo-img"
            />
          </StyledButton>
          {isMobile ? (
            <BurgerMenu />
          ) : (
            <Box className="link-container">
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/#que-hacemos"
              >
                ¿QUÉ HACEMOS?
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/#servicios"
              >
                NUESTROS SERVICIOS
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/#porque-elegirnos"
              >
                ¿POR QUÉ ELEGIRNOS?
              </StyledButton>
              <StyledButton
                className="navbar-links"
                component={Link}
                to="/#contacto"
              >
                CONTACTO
              </StyledButton>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
